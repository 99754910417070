<template>
  <div class="helper">
    <div class="home-wrapper">
      <v-container class="mx-auto" style="max-width: 1280px;">
        <v-row
          class="align-center justify-space-between two-rows"
          style="gap: 2rem;"
        >
          <!-- Left Column -->
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-column align-center content-left"
          >
            <div class="content-left">
              <!-- Header -->
              <transition name="fade" mode="out-in">
                <div :key="activeHeader" class="header-container">
                  <h1 class="heading">{{ headers[activeHeader] }}</h1>
                  <h1 class="subheading primary--text">{{ $lang.labels.withFlowy }}</h1>
                </div>
              </transition>

              <!-- Features -->
              <div class="features-container">
                <div class="features">
                  <div
                    v-for="(feature, index) in features[activeFeature]"
                    :key="feature.id"
                    class="feature"
                    :style="getAnimationStyle(index)"
                  >
                    <div class="icon-circle">
                      <v-icon class="icon" size="50">{{ feature.icon }}</v-icon>
                    </div>
                    <h4 class="feature-text">{{ feature.text }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <!-- Right Column -->
          <v-col
            cols="12"
            md="6"
            class="d-flex align-center content-right"
          >
            <div class="diagram-wrapper">
              <img
                src="/images/home-diagram.png"
                alt="Workflow Diagram"
                class="diagram"
              />
              <div class="d-flex justify-end mt-8">
                <!--                <v-btn-->
                <!--                  class="onboarding-btn slide-in-btn rounded-pill px-5 py-5 d-flex align-center"-->
                <!--                  color="primary"-->
                <!--                  large-->
                <!--                  data-cy="start-onboarding-btn"-->
                <!--                >-->
                <!--                  <span class="btn-text">{{ $lang.actions.startOnboarding }}</span>-->
                <!--                  <v-icon-->
                <!--                    size="50"-->
                <!--                    class="ml-4"-->
                <!--                    style="margin-left: 1rem; transform: translateY(2px);"-->
                <!--                    right-->
                <!--                  >-->
                <!--                    mdi-arrow-right-thin-->
                <!--                  </v-icon>-->
                <!--                </v-btn>-->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!--  Footer -->

    <div class="data-help text-center py-1 px-2 align-center" style="width: 100%">
      <v-row no-gutters class="px-2 display-flex justify-center justify-md-start">
        <v-col cols="12" md="6" lg="4" class="text-center text-md-left pl-2 mb-2 mb-md-0">
          Copyright © {{ new Date().getFullYear() }} <a href="https://damudo.at" target="_blank">Damudo</a>, All rights
          Reserved
        </v-col>
        <v-col cols="12" md="6" lg="8" class="text-right text-sm-center">
          <v-row no-gutters class="justify-center justify-md-end">
            <div class="mb-2 mb-md-0 mr-1">
              User: <span class="color-primary">{{ user ? user.name + ',' : '' }}</span>
            </div>
            <div class="mb-2 mb-md-0">
              BRANCH: <span class="color-primary">{{ backEnd.branch }}</span>, COMMIT:
              <span class="color-primary">{{ backEnd.commit.id }}</span>,
              <!--              TIME: <span class="color-primary">{{ backEnd.commit.time | formatDateTimePrecise }}</span>-->
            </div>
            <div v-if="$vuetify.breakpoint.lgAndUp" class="px-2"> | </div>
            <div class="">
              {{ $lang.labels.frontEnd }}: <span class="color-primary">{{ frontEnd }}</span>, TIME: <span class="color-primary">{{ frontEndBuildTime | formatDateTimePrecise }} UTC</span>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'

export default {
  data() {
    return {
      activeFeature: 0, // Track the currently active feature
      activeHeader: 0, // Track the currently active header
      headers: [
        this.$lang.labels.homeHeaderFirst,
        this.$lang.labels.homeHeaderSecond,
        this.$lang.labels.homeHeaderThird
      ],
      features: [
        [
          { id: 0, text: this.$lang.labels.homeFeatureFirst1, icon: 'mdi-cogs' },
          { id: 1, text: this.$lang.labels.homeFeatureFirst2, icon: 'mdi-magnet' },
          { id: 2, text: this.$lang.labels.homeFeatureFirst3, icon: 'mdi-database-check-outline' },
          { id: 3, text: this.$lang.labels.homeFeatureFirst4, icon: 'mdi-link-variant' }
        ],
        [
          { id: 4, text: this.$lang.labels.homeFeatureSecond1, icon: 'mdi-chart-line' },
          { id: 5, text: this.$lang.labels.homeFeatureSecond2, icon: 'mdi-cloud-upload-outline' },
          { id: 6, text: this.$lang.labels.homeFeatureSecond3, icon: 'mdi-file-chart' },
          { id: 7, text: this.$lang.labels.homeFeatureSecond4, icon: 'mdi-shield-lock' }
        ],
        [
          { id: 8, text:  this.$lang.labels.homeFeatureThird1, icon: 'mdi-folder-outline' },
          { id: 9, text:  this.$lang.labels.homeFeatureThird2, icon: 'mdi-account-group' },
          { id: 10, text: this.$lang.labels.homeFeatureThird3, icon: 'mdi-alert-circle-check' },
          { id: 11, text: this.$lang.labels.homeFeatureThird4, icon: 'mdi-tune-vertical' }
        ]
      ],
      displayDuration: 10000,
      animationDelayBase: 400, // Base delay in milliseconds for staggered animation
      user: localStorage.userData ? JSON.parse(localStorage.userData) : null,
      frontEnd: '',
      frontEndBuildTime: '',
      backEnd: {
        branch: '',
        commit: {
          id: '',
          time: ''
        }
      }
    }
  },
  mounted() {
    this.startAnimationCycle()

    this.frontEnd = localStorage.frontendVersion ? localStorage.frontendVersion : ''

    this.frontEndBuildTime = localStorage.frontendBuildtime ? localStorage.frontendBuildtime : ''

    this.backEnd = localStorage.backendVersion ? JSON.parse(localStorage.backendVersion) : {
      branch: '',
      commit: {
        id: '',
        time: ''
      }
    }
  },
  methods: {
    startAnimationCycle() {
      setInterval(() => {
        this.activeFeature = (this.activeFeature + 1) % this.features.length
        this.activeHeader = (this.activeHeader + 1) % this.headers.length
      }, this.displayDuration) // Adjust timing as needed
    },
    getAnimationStyle(index) {
      const delay = this.animationDelayBase * index

      return {
        animationDelay: `${delay}ms`
      }
    }
  }
}
</script>
